@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('./phone-input.css');

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  outline: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #f8f9fa;
}

a {
  text-decoration: none;
  color: inherit;
}
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

body,
button,
input,
textarea {
  font-family: "Roboto";
  color: inherit;
  padding: 0;
}

button {
  border: 0;
  background: none;
}

input,
textarea {
  -webkit-appearance: none;
  border-radius: 0;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  color: #252733;
}

::-webkit-scrollbar {
  /* background: #e9ecef; */
  /* position: absolute; */
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #00a0ff;
  border-radius: 8px;
}
