.phone-input {
	display: flex;
	font-size: 14px!important;
	font-weight: 400;
	background-color: #fcfdfe!important;
	border: 1px solid #f0f1f7!important;
	color: #9fa2b4;
	line-height: 1.5;
	min-height: 42px;
	padding: 10px 16px;
	border-radius: 4px!important;
	width: 100%!important;
	margin: 0
}

.phone-dropdown-button {
	background-color: #fcfdfe!important;
	border: 1px solid #f0f1f7!important;
	color: #9fa2b4;
	line-height: 1.5;
	border-radius: 4px!important;
}

.phone-dropdown {
	width: 310px;
	border-radius: 4px;
	box-shadow: 1px 2px 10px rgb(0 0 0 / 15%)
}
